import './Introduction.css';
import CVFile from "./cv-compressed.pdf";

function Introduction() {
	return (
		<div className="introduction">
			<div className="splash">
				<div className="splash-info">
					<span className="first-name krona">benjamin</span>
					<span className="last-name krona">ludziński</span>
					<span className="city">@ Espoo, Finland</span>
				</div>
			</div>
			<div className="quote krona">
				“Software engineering is the art of turning imagination into innovation, logic into reality, and complexity into simplicity.”
			</div>
			<button className="cv-download-btn" onClick={() => window.open(CVFile)}>get my curriculum vitae / resume pdf →</button>
			<div className="details-view">
				<div className="video-cv-cont">
					<span className="krona">get to know me</span>
					<video className="video-cv">
						<source src="#" type="video/mp4" />
					</video>
				</div>
				<div className="about-me">
					As a <b>software engineer</b>, I help companies harness the <b>power of technology</b> to achieve their <b>goals</b> and <b>drive innovation</b>. By designing and developing robust software solutions, I enable businesses to <b>streamline processes, enhance efficiency, and improve their overall performance</b>. By staying abreast of industry trends and emerging technologies, I provide <b>valuable insights</b> and <b>recommendations</b> to stay <b>competitive</b> in a <b>rapidly evolving digital landscape</b>. Ultimately, my role as a software engineer is to <b>empower businesses</b> to unlock their <b>full potential</b> and make a meaningful impact in their respective industries.
				</div>
			</div>
			<div className="footer">
				&copy; 2023 Benjamin Ludziński
			</div>
		</div>
	);
}

export default Introduction;

