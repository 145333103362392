import './App.css';
import { useState } from 'react';
import Introduction from "./Introduction.js";
import Contact from "./Contact.js";

function App() {
	
	let [openedTab, changeTab] = useState("introduction");

	return (
		<div className="app">
			<header>
				<span className="krona">ludzin.ski</span>
			</header>
			<div className="nav-bar">
				<button onClick={() => changeTab("introduction")}>introduction</button>
				<button>&#x2022;</button>
				<button onClick={() => changeTab("contact")}>contact</button>
			</div>
			{openedTab === "contact" ? <Contact /> : <Introduction />}
			
		</div>
	);
}

export default App;

