import './Contact.css';
import ContactImg from "./contact-img.png";

function Contact() {

	return (
		<div className="contact">
			<span class="contact-heading krona">contact me</span>
			
			<div className="contact-types-cont">
				<img src={ContactImg} alt="my photograph" />
				<div className="contact-direct">
					<span><b>Benjamin Ludziński</b> (he/him)</span>
					<br />
					<a href="mailto:benjamin@ludzin.ski">benjamin@ludzin.ski</a>
					<span>feel free to ask for a phone number</span>
					<br />
					<a href="https://www.linkedin.com/in/benjamin-ludzi%C5%84ski-4620a1265/">LinkedIn</a>
					<a href="https://github.com/non-root-user">GitHub</a>
				</div>
			</div>
			<div className="footer">
				&copy; 2023 Benjamin Ludziński
			</div>
		</div>
	);
}

export default Contact;

